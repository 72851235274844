<template>
  <div class="Report__Entity">
    <Entity ref="entity" entity-type="Report" :entity-id="reportId" :innerComponent="innerComponent"
      innerComponentTitle="פרטי דוח" title="דוח תעבורה" back-button-text="רשימת דוחות" back-button-url="reports"
      :widgets="widgets" :actions="actions" :preview="preview" :read-only="readOnly" :entityReady="entityReady"
      :activities="[]" @onFormSave="onFormSave" @onFormDiscard="onFormDiscard">
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר דוח:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ ReportNumber }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר רכב:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ Vehicle.Name }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">עיריה:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ Municipality && Municipality.Name }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage flex-between">
          <span>זמן קבלת הדוח
            <FieldDateHourInline :value="DateTaken" class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            " />
          </span>
          <b-tooltip label="לחץ לתשלום">
            <a target="_blank" v-if="PaymentUrl && isEntityPage" :href="PaymentUrl" class="Pay__Icon"><i><img
                  src="@/assets/payment.svg" alt="" /></i></a>
          </b-tooltip>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
// import DriverService from '@/services/DriverService';
import Entity from "@/components/Entity.vue";
import ReportEntityDetails from "@/components/ReportEntityDetails.vue";
// import EntityMixin from '@/mixins/EntityMixin';
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import ReportService from "@/services/ReportService";
import WidgetService from "@/services/WidgetService";
import VehicleService from "@/services/VehicleService";
import ArchiveService from "@/services/ArchiveService";
import DialogReportHistory from "@/components/DialogReportHistory.vue";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
  LoadingProgrammatic as Loading,
} from "buefy";
import FieldDateHourInline from "../components/Fields/FieldDateHourInline.vue";
import DriverService from "../services/DriverService";

const { mapFields } = createHelpers({
  getterType: "report/getField",
  mutationType: "report/updateField",
});

export default {
  components: {
    Entity,
    FieldDateHourInline,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    reportId() {
      return this.$route.params.id || this.id;
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    widgets() {
      const widgets = [
        {
          ...WidgetService.ReportActivities,
          namespace: "report",
        },
        { ...WidgetService.ReportPayment, namespace: "report" },
        { ...WidgetService.ReportStatuses, namespace: "report" },
        { ...WidgetService.ReportAlerts, namespace: "report" },
        {
          ...WidgetService.Vehicle,
          namespace: "report",
          fetch: true,
          delegate: VehicleService.getVehicle,
        },
        { ...WidgetService.Driver, namespace: "report", fetch: true, delegate: DriverService.getDriver },
        { ...WidgetService.DriverContractForReport, namespace: "report" },
        {
          ...WidgetService.Profile,
          isHidden: !this.isLeasingCompany,
          namespace: "report",
        },
        { ...WidgetService.VehicleContractForReport, namespace: "report" },
        {
          ...WidgetService.TransferStatusesCompanyWidget,
          namespace: "report",
          isHidden: !this.isLeasingCompany && !this.isAdmin,
        },
        {
          // מסמך הסבת חברה
          ...WidgetService.TransferDocumentWidget,
          namespace: "report",
          isHidden: !this.isLeasingCompany && !this.isAdmin,
        },
        {
          ...WidgetService.TransferStatusesDriverWidget,
          namespace: "report",
        },
        {
          // מסמך הסבת נהג
          ...WidgetService.TransferDriverDocumentWidget,
          namespace: "report",
          isHidden: !this.isLeasingCompany && !this.isAdmin,
        },
        // { ...WidgetService.ReportDelivery, namespace: 'report' },
        // { ...WidgetService.Contract, namespace: 'report' },
        // { ...WidgetService.ReportPhotos, namespace: "report" },
        // { ...WidgetService.ReportHistory, namespace: 'report' },
      ];
      return widgets;
    },
    actions() {
      return this.isAdmin
        ? [
          {
            text: "אימות דוח",
            onClick: () => {
              Dialog.confirm({
                message: "האם לבצע אימות עבור דוח זה?",
                onConfirm: () => {
                  ReportService.scanDetailsFromMunic({
                    reportNumber: this.ReportNumber,
                  });
                  Toast.open({
                    type: "is-success",
                    message:
                      "הסריקה החלה... התהליך עשוי להמשך מספר דקות. ניתן להמשיך בעבודה",
                    duration: 10000,
                  });
                },
                confirmText: "בטוח!",
                cancelText: "בטל",
              });
            },
          },
          {
            text: "היסטוריית אירועים",
            onClick: () => {
              ArchiveService.getHistory(this.reportId).then((r) => {
                this.data = r.data;

                this.$buefy.modal.open({
                  component: DialogReportHistory,
                  props: {
                    data: this.data,
                  },
                  canCancel: false,
                  hasModalCard: true,
                  trapFocus: true,
                });
              });
            },
          },
          {
            text: "משיכת מסמכי הסבה מ Report",
            onClick: () => {
              const loading = Loading.open({
                isFullPage: true,
              });
              ReportService.pullTransfersFromReport([this.reportId])
                .then((r) => {
                  Dialog.confirm({
                    message: `
                                <div style="max-height:300px">
                                    <div>הפעולה ${r.data[0].Success ? `בוצעה` : `נכשלה`}!</div>
                                    <div>
                                      ${r.data[0].Message || ""}
                                    </div>
                                </div>`,
                    confirmText: 'סגור',
                    canCancel: false
                  });
                }).catch(() => {
                  this.$buefy.toast.open({
                    type: "is-danger",
                    message: "קרתה תקלה, הפעולה לא התבצעה!",
                    duration: 8000,
                  });
                }).finally(() => {
                  loading.close();
                });
            },
          },
        ]
        : null;
    },
    innerComponent() {
      return ReportEntityDetails;
    },
    ...mapFields(["ReportNumber", "Municipality", "DateTaken", "Vehicle", "PaymentUrl"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
      isEntityPage: false
    };
  },
  async created() {
    this.init();
    this.isEntityPage = !this.$route.params.id;
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.reportId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      ReportService.update(this.reportId, {
        ...this.$store.state.report,
        ...model,
      })
        .then(() => {
          this.init(refresh);
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "report/loadReport",
    }),
  },
  // watch: {
  //   id(old, newValue) {
  //     console.log(old, newValue);
  //   },
  // },
};
</script>

<style scoped lang="scss">
.flex-between {
  display: flex;
  justify-content: space-between;

  .Accident__Entity__Details__BaseDetails__Stage__Value {
    padding-right: 0;
  }

  ::v-deep .b-tooltip {
    margin-left: 10px;
  }

  .Pay__Icon {
    border-radius: 3px;
    color: #0091ae;
    position: relative;
    right: -5px;
    font-size: 14px;

    .payment-btn {
      color: white;
      position: absolute;
      left: 20px;
      background-color: #ff7a59;
      height: 45px;
      margin: 0 10px;
      border: none;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #fb6743;
      }
    }

    i {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
